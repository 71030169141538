import * as React from "react";
import {
    BrowserButton,
    PagingControls,
    PagingControl,
    GroupRoot,
    Root
} from './profile-browser-styles';
import { resolveIcon } from "../../../framework/icon-resolver";
import LinksBlock from "../../_molecules/linksblock";
import { SpecialismIcon } from '../specialisms/specialisms-styles';
import Block from "../../_molecules/block";
import { GitHubRepoModel } from '../../../services/models/github-repo.model';
import { NpmRepoModel } from '../../../services/models/npm-repo.model';
import { CodepenModel } from '../../../services/models/code-pen.model';
import { CodeChallengeModel } from '../../../services/models/code-challenge.model';

 interface PageState {
     items: ProfileTileProps[]
 }

 interface TabState {
    pages: PageState[];
 }

 interface BrowserState {
     githubTab: TabState;
     npmTab: TabState;
     challengeTab: TabState;
     penTab: TabState;
     activeTab: string;
 }

interface TabProps {
    tab: TabState,
    contentName: string,
    navigateBack: () => void,
    navigateNext: () => void,
    icon: any
}

interface ProfileTileProps {
    url: string;
    name: string;
    type: string;
}

const Group = (props: TabProps) => {
    const { tab, contentName, icon } = props;

    const [index, setIndex] = React.useState(0);

    function navigateNext() {
        setIndex(index + 1);
    }

    function navigatePrev() {
        setIndex(index - 1);
    }

    return tab && tab.pages && (
        <GroupRoot>            
            { tab.pages[index] &&
                <Block
                    title={contentName}
                    icon={icon}
                >
                    {
                        tab &&
                        <LinksBlock
                            links={tab.pages[index].items.map(i => ({
                                label: i.name,
                                link: i.url
                            }))}                    
                        />                    
                    }
                </Block>
            }
            <PagingControls>
                { (index > 0) && <PagingControl><BrowserButton onClick={() => navigatePrev()}>Prev</BrowserButton></PagingControl> }
                { (index < tab.pages.length - 1) && <PagingControl><BrowserButton onClick={() => navigateNext()}>Next</BrowserButton></PagingControl> }
            </PagingControls>
        </GroupRoot>   
    ) || null;
};

export interface GitHubRepoData {
    collection: GitHubRepoModel[]
}

export interface NpmPackageData {
    collection: NpmRepoModel[]
}

export interface CodepenData {
    collection: CodepenModel[]
}

export interface CodeChallengeData {
    collection: CodeChallengeModel[]
}

export interface ProfileData {
    githubrepos: GitHubRepoData;
    npmpackages: NpmPackageData;
    codepens: CodepenData;
    codechallenges: CodeChallengeData;
}

export interface ProfileBrowserProps {
    data: ProfileData
}

function chunk(array, size) {
    const chunked_arr = [];
    let index = 0;
    while (index < array.length) {
      chunked_arr.push(array.slice(index, size + index));
      index += size;
    }
    return chunked_arr;
}

const ProfileBrowser = (props: ProfileBrowserProps) => {
    const [tabs, setTabs] = React.useState<BrowserState>({
        githubTab: null,
        npmTab: null,
        challengeTab: null,
        penTab: null,
        activeTab: null
    });

    React.useEffect(() => {
        setTabs({
            ...tabs,
            githubTab: {
                pages: chunk(props.data.githubrepos.collection, 5).map(c => ({
                    items: c
                }))
            },
            npmTab: {
                pages: chunk(props.data.npmpackages.collection, 5).map(c => ({
                    items: c
                }))
            },
            challengeTab: {
                pages: chunk(props.data.codechallenges.collection, 5).map(c => ({
                    items: c
                }))
            },
            penTab: {
                pages: chunk(props.data.codepens.collection, 5).map(c => ({
                    items: c
                }))
            }
        })
    }, []);

    return (
        <Root>
            <Group
                tab={tabs.githubTab}
                contentName="github repositories"
                navigateBack={() => {}}
                navigateNext={() => {}}
                icon={() => (
                    <SpecialismIcon
                        icon={resolveIcon('github')}
                        size="3x"
                    />
                )}
            />
            <Group
                tab={tabs.npmTab}
                contentName="npm packages"
                navigateBack={() => {}}
                navigateNext={() => {}}
                icon={() => (
                    <SpecialismIcon
                        icon={resolveIcon('npm')}
                        size="3x"
                    />
                )}
            />
            <Group
                tab={tabs.challengeTab}
                contentName="code challenges"
                navigateBack={() => {}}
                navigateNext={() => {}}                
                icon={() => (
                    <SpecialismIcon
                        icon={resolveIcon('codewars')}
                        size="3x"
                    />
                )}
            />
            { tabs.penTab && tabs.penTab.pages.length > 0 && <Group
                tab={tabs.penTab}
                contentName="codepens"
                navigateBack={() => {}}
                navigateNext={() => {}}
                icon={() => (
                    <SpecialismIcon
                        icon={resolveIcon('codepen')}
                        size="3x"
                    />
                )}
            /> }
        </Root>
    )
}

export default ProfileBrowser;
