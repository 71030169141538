import styled from "styled-components";
import { textSecondary } from '../../../styles/_theme-variables';
import { transitionColor } from '../../../styles/_mixins';
import { fluidFontSizes } from '../../../styles/_calculations';

const HeaderColor = textSecondary;

export const Header = styled.h3`
    color: ${HeaderColor};
    ${transitionColor}
    ${fluidFontSizes.header4Size}
    margin-bottom: 0.5em;
    text-align: center;
`