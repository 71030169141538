import React from "react";
import { withTheme } from "styled-components";
import { resolveColor } from "./resolve-color"; 

const BBC = ({ theme, isDarkMode }) => (
    <svg version="1.1"
        id="svg2"
        x="0px" y="0px"
        width="100%"
        height="auto"
        viewBox="-400 -67.353 1000 285.013"
        enable-background="new -400 -67.353 1000 285.013"    
    >
        <path
            fill={resolveColor('#000000', theme.styles.color.textPrimary, isDarkMode)}
            d="M141.817,116.848c0,30.971-38.541,29.133-38.541,29.133h-38.54V90.018h38.54
            C142.939,89.752,141.817,116.848,141.817,116.848 M64.736,4.67h29.368c30.496,1.611,29.345,24.316,29.345,24.316
            c0,28.216-33.721,28.676-33.721,28.676H64.736V4.67z M134.688,68.667c0,0,26.38-11.241,26.145-41.057
            c0,0,4.012-48.864-60.729-54.824H28.266v204.849h82.344c0,0,68.802,0.205,68.802-57.799
            C179.411,119.836,181.038,80.363,134.688,68.667 M-51.227-67.353h302.453V217.66H-51.227V-67.353z M-206.959,116.848
            c0,30.971-38.541,29.133-38.541,29.133h-38.543V90.018h38.543C-205.834,89.752-206.959,116.848-206.959,116.848 M-284.043,4.67
            h29.374c30.497,1.611,29.343,24.316,29.343,24.316c0,28.216-33.719,28.676-33.719,28.676h-24.998V4.67z M-214.085,68.667
            c0,0,26.384-11.241,26.147-41.057c0,0,4.009-48.864-60.732-54.824h-71.841v204.849h82.349c0,0,68.801,0.205,68.801-57.799
            C-169.361,119.836-167.74,80.363-214.085,68.667 M-400-67.353h302.453V217.66H-400V-67.353z M538.301-12.528v37.846
            c0,0-36.942-22.702-77.764-23.159c0,0-76.161-1.495-79.594,73.005c0,0-2.751,68.513,78.676,72.417c0,0,34.165,4.115,80.514-25.441
            v39.195c0,0-62.173,36.939-134.197,8.488c0,0-60.545-22.109-62.851-94.659c0,0-2.518-74.619,78.23-99.389
            c0,0,21.563-8.255,60.313-4.586C481.629-28.812,504.789-26.521,538.301-12.528 M297.547,217.66H600V-67.353H297.547V217.66z"/>
    </svg>
);

export default withTheme(BBC);
