import styled, { css, keyframes } from 'styled-components';
import Img from 'gatsby-image';
import BackgroundImage from 'gatsby-background-image';
import { transparentize } from "polished";

import HtmlContent from '../../_molecules/html-content/index';
import { fluidFontSizes } from '../../../styles/_calculations';
import { Link } from "gatsby";
import { bpMobilePhablet, leftAccentColorLighter, primaryColorDark, borderRadius } from '../../../styles/_theme-variables';

const TextColor = leftAccentColorLighter;
const CTABgHoverColor = primaryColorDark;

const RootStyles = css`
    width: 100%;
    min-height: 80vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

export const Root = styled(BackgroundImage)`
    ${RootStyles}
`;

export const Logos = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    overflow: hidden;
`;

export const ContentWrapper = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: ${props => transparentize(0.09, props.theme.styles.color.basicBlack)};
    border-bottom: 1px solid ${TextColor};
    position: relative;
`;

export const Content = styled.div`
    @media screen and (max-width: 64em) {
        padding: 0.5em;
    }
`;

export const Grid = styled.div`
    display: flex;
    flex-direction: column;

    @media screen and (min-width: ${bpMobilePhablet}) {
        flex-direction: row;
    }
`

export const Left = styled.div`
    flex: 1;
    padding: 1em;
    display: flex;
    flex-direction: column;
    justify-content: center;

    & h2 {
        ${fluidFontSizes.header2Size};
        color: ${TextColor};
        margin-bottom: 1em;
    }

    & p {
        ${fluidFontSizes.bodySize};
        color: ${TextColor};
    }
`;

export const Html = styled(HtmlContent)`
`;

export const Right = styled.div`
    flex: 1;
    padding: 2em;
`;

export const ProfilePic = styled(Img)`
    border-radius: ${borderRadius};
`;

export const CTA = styled(Link)`
    border: 1px solid ${TextColor};
    ${fluidFontSizes.bodySize}
    padding: 0.75em;
    display: inline-block;
    border-radius: 2px;
    color: ${TextColor};
    cursor: pointer;
    transition: all 200ms ease-in;
    text-decoration: none;

    &:hover {
        background-color: ${TextColor};
        color: ${CTABgHoverColor};
    }
`;

const floatUp = keyframes`
  0% { top: 110%; top: calc(100% + 2em); transform: rotate(0deg); }
  100% { top: -25%; top: -2em; transform: rotate(359deg); }
`
export const Icon = styled.img`
    max-width: 5rem;
    -webkit-font-smoothing: antialiased;
    transition: 300ms all;
    position: absolute;
    font-size: 10vw; // rand 8,9,10
    animation: 10s ${floatUp} ease-in infinite;
    top: 100%;
    &:nth-child(even) {
      animation-direction: reverse;
    }
`