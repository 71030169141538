import React from "react"
import { graphql } from 'gatsby';
import { FeatureToggle } from "react-feature-toggles/lib";

import SEO from "../components/seo";
import HomeHero from "../components/_organisms/home-hero";
import Specialisms from "../components/_organisms/specialisms";
import SectionHeader from "../components/_molecules/section-header";
import FeaturedCompanies from "../components/_organisms/featured-companies";
import FeaturedPortfolio from "../components/_organisms/featured-portfolio";

import {
  Section,
  SpecialismSection,
  CompaniesSection,
  ProjectsSection,
  ProfileBrowserWrapper,
  CompaniesHeader,
  CompaniesSectionHeaderOverrides,
  PortfolioSectionHeaderOverrides,
  Link,
  ProfileSection
} from "../styles/pages/home-styles";

import {
  Header,
  Separator,
  CenterWrapper
} from "../components/primitives";

import ProfileBrowser from "../components/_organisms/profile-browser";
import { toggleNames } from "../features";
import FeaturedPosts from "../components/_organisms/featured-posts";
import Feeds from "../components/_organisms/feeds";
import { FeedSection } from '../styles/pages/home-styles';

const { PROFILE, FEEDS } = toggleNames;

const IndexPage = ({ data }) => {
  return (
    <>
      <SEO
        title="Home"
        description={data.jsonJson.metaDescription}
        image={data.jsonJson.content.openGraphImage.src.childImageSharp.fixed.src}
        slug="/"
      />
      <div data-testid="home-hero">
        <HomeHero
          images={[data.jsonJson.content.welcomeImage]}
          html={data.jsonJson.content.blurb.html}
          learnMorePath="/about"
          profilePic={data.jsonJson.content.profilePic.src.childImageSharp.fluid}
        />
        <SpecialismSection>
          <CenterWrapper>
            <Specialisms
              technicalItems={data.jsonJson.content.specialisms.technical}
              catalyticItems={data.jsonJson.content.specialisms.catalytic}
              downtimeItems={data.jsonJson.content.specialisms.downtime}
            />
          </CenterWrapper>
        </SpecialismSection>
        <CompaniesSection>
          <CenterWrapper>
            <CompaniesHeader>
                <SectionHeader escapeHatchStylesheet={{ root: CompaniesSectionHeaderOverrides }}>
                  Who I've worked with...
                </SectionHeader>
            </CompaniesHeader>
          </CenterWrapper>
          <CenterWrapper>
            <FeaturedCompanies
              featuredCompanies={data.jsonJson.content.featuredCompanies}
            />
          </CenterWrapper>
        </CompaniesSection>
        <ProjectsSection>
          <CenterWrapper>
            <CompaniesHeader>
              <SectionHeader escapeHatchStylesheet={{ root: PortfolioSectionHeaderOverrides }}>
                Some of my work...
              </SectionHeader>
              <Link to="/portfolio">
                view more
              </Link>
            </CompaniesHeader>
          </CenterWrapper>
          <CenterWrapper>
            <FeaturedPortfolio
              featuredProjects={data.jsonJson.content.featuredProjects}
            />
          </CenterWrapper>
        </ProjectsSection>
        <Section>
          <CenterWrapper>
            <CompaniesHeader>
              <SectionHeader>
                My recent writing...
              </SectionHeader>
              <Link to="/blog">
                view more
              </Link>
            </CompaniesHeader>
          </CenterWrapper>
          <CenterWrapper>
            <FeaturedPosts
              posts={data.latestPosts.edges}
            />
          </CenterWrapper>
        </Section>
        <FeatureToggle featureName={FEEDS}>
          <FeedSection>
            <CenterWrapper>
              <CompaniesHeader>
                <SectionHeader>
                  What's new...
                </SectionHeader>
              </CompaniesHeader>
            </CenterWrapper>
            <CenterWrapper>
              <Feeds
                tweets={data.tweets.edges}
                user={data.twitterUser.user}
                resources={data.g33kchris.resources}
                videos={data.g33kchris.videos}
              />
            </CenterWrapper>
          </FeedSection>   
        </FeatureToggle>
        <FeatureToggle featureName={PROFILE}>
          <ProfileSection>
            <CenterWrapper>
              <CompaniesHeader>
                <SectionHeader>
                    Some of my code...
                </SectionHeader>
              </CompaniesHeader>
            </CenterWrapper>
            <CenterWrapper>
              <ProfileBrowserWrapper>
                <ProfileBrowser data={data.g33kchris} />
              </ProfileBrowserWrapper>
            </CenterWrapper>
          </ProfileSection>
        </FeatureToggle>   
      </div>
  </>
  )
}

export const pageQuery = graphql`
  query homeQuery {
    jsonJson(id: {eq: "home_page"}) {
      id
      title
      metaDescription
      content {
        blurb {
          html
        }
        featuredCompanies {
          logo
          title
        }
        featuredPost {
          id
          heroImages {
            name
            src {
                childImageSharp {
                    fixed(width: 700, height: 350, cropFocus: NORTH) {
                        ...GatsbyImageSharpFixed
                    }  
                    fluid(maxWidth: 700, maxHeight: 350, cropFocus: NORTH) {
                        ...GatsbyImageSharpFluid_noBase64
                    }              
                }   
            }
          }
          slug
          title
          publishDate
          metaDescription
        }
        featuredProjects {
          id
          slug
          series
          title
          metaDescription
          date
          logo {
            name
            src {
                childImageSharp {
                    fluid(maxHeight: 120) {
                      ...GatsbyImageSharpFluid_noBase64
                    }
                    portrait: fluid(maxWidth: 120, maxHeight: 120) {
                      ...GatsbyImageSharpFluid_noBase64
                    }
                }   
            }
          }
          heroImages {
            name
            src {
                  childImageSharp {
                    fluid(maxWidth: 570, maxHeight: 320, cropFocus: NORTH) {
                      ...GatsbyImageSharpFluid_noBase64
                    }                     
                  }   
              }
          }
        }
        profilePic {
          name
          src {
            childImageSharp {
                fluid(quality: 90, maxWidth: 800, maxHeight: 600, cropFocus: ATTENTION, grayscale: true) {
                    ...GatsbyImageSharpFluid_withWebp_tracedSVG
                }
            }          
          }
        }
        welcomeImage {
          name
          src {
            childImageSharp {
                fluid(quality: 90, maxWidth: 1376, maxHeight: 489) {
                    ...GatsbyImageSharpFluid_withWebp_tracedSVG
                }
                fluidThumb: fluid(quality: 90, maxWidth: 1920, maxHeight: 1080, cropFocus: NORTH) {
                    ...GatsbyImageSharpFluid_withWebp
                }   
            }          
          }
        }
        openGraphImage: welcomeImage {
          name
          src {
            childImageSharp {
              fixed(width: 1200, height: 630) {
                src
              }
            }
          }
        }
        specialisms {
          technical
          catalytic
          downtime
        }
      }
    }
    latestPosts: allPostsJson(
      limit: 3,
      sort: {order: DESC, fields: publishDate}
    ) {
        edges {
            node {
                id
                title
                metaDescription
                slug
                heroImages {
                  name
                  src {
                      childImageSharp {
                          fixed(width: 700, height: 350, cropFocus: NORTH) {
                              ...GatsbyImageSharpFixed
                          }  
                          fluid(maxWidth: 700, maxHeight: 350, cropFocus: NORTH) {
                              ...GatsbyImageSharpFluid_noBase64
                          }              
                      }   
                  }
                }
                publishDate
                author: _author {
                    name
                }
            }
        }
    }
    tweets: allTwitterStatusesUserTimelineOwnTweetsQuery {
      edges {
        node {
          full_text
          favorite_count
          retweet_count
          created_at
          user {
            name
            url
            profile_image_url
            screen_name
          }
          entities {
            urls {
              display_url
            }
          }
        }
      }
    }
    twitterUser: twitterStatusesUserTimelineOwnTweetsQuery {
      user {
        profile_image_url_https
        name
        url
        screen_name
      }
    }
    g33kchris {      
      videos(count: 5, orderBy: "saveDate", desc: true) {
        title
        url
      }
      resources: knowledgeresources(count: 5, orderBy: "saveDate", desc: true) {
        title
        url
      }
      githubrepos {
        collection {
          slug
          author
          name
          starCount
          forkCount
          url
        }
      }
      npmpackages {
        collection {
          name
          url
          weeklyDownloads
          version
          collaborators
        }
      }
      codepens {
        collection {
          name
          link
          guid
          creator
          description
        }
      }
      codechallenges {
        collection {
          id
          name
          slug
          url
        }
      }
    }    
  }
`;

export default IndexPage