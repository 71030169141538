import styled from 'styled-components';
import { headerFontMixin, transitionColor } from '../../../styles/_mixins';
import { fluidFontSizes } from '../../../styles/_calculations';
import { textPrimary } from '../../../styles/_theme-variables';
import { withEscapeHatchStyles } from '../../../styles/sprinkles';

export const Root = withEscapeHatchStyles(styled.h3`
    ${headerFontMixin};
    ${fluidFontSizes.header3Size}
    color: ${textPrimary};
    ${transitionColor}
    text-align: center;
`);