import React from "react";
import Block from "../block";
import { resolveIcon } from "../../../framework/icon-resolver";
import { SpecialismIcon } from '../../_organisms/specialisms/specialisms-styles';
import { Root, Header, HeaderLink, Tweet, TweetContent, TweetHeader, TweetLink, Date } from './twitter-feed-styles';

const TwitterFeed = ({ tweets, user}) => {
    return (
        <Block
            title={`@${user.screen_name}`}
            icon={() => (
                <SpecialismIcon
                    icon={resolveIcon('twitter')}
                    size="3x"
                />
            )}
        >
            <Root>
                <Header>
                    <div>
                        <HeaderLink href={user.url} target="_blank">{user.name}</HeaderLink>
                    </div>
                </Header>
                {tweets.map(({ node }) => (
                    <Tweet key={node.favorite_count}>
                        <TweetContent>{node.full_text.split(`#`)[0].split(`http`)[0]}</TweetContent>
                        {/* {node.entities.hashtags && <div className="tweet-hastags">{node.entities.hashtags.map(({ text }) => (
                            <a href={`https://twitter.com/hashtag/${text}`} key={text} className="hashtag">#{text}</a>
                        ))}</div> } */}
                        <TweetHeader>
                            {node.entities.urls.map(({ display_url }) => (
                                <TweetLink href={display_url} className="tweet-link" key="1">{ display_url }</TweetLink>
                            ))}
                            <Date>{node.created_at.split(` `, 3).join(` `)}</Date>
                        </TweetHeader>
                    </Tweet>
                ))}
            </Root>
        </Block>
    )
}

export default TwitterFeed;
