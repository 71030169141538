import React from "react";
import { Projects, PortfolioItem } from "./featured-portfolio-styles";
import Tile from "../../_molecules/tile";
import { withRenderPropElements } from "../../../styles/sprinkles";
import BaseElements from '../../_molecules/tile/tile-styles/_base';

export interface FeaturedPortfolioProps {
    featuredProjects: any[]
}

// Note: Render props can be composed from the parent
const StyledTile = withRenderPropElements(Tile)(BaseElements);

const FeaturedPortfolio = (props: FeaturedPortfolioProps) => (
    <Projects>
    {
        props.featuredProjects.map(p => (
        <PortfolioItem key={`home-featured-projects-${p.title}`}>
            <StyledTile
                slug={p.slug}
                title={p.title}
                subtitle={p.series}
                date={p.date}
                image={p.heroImages[0]}
                logo={p.logo}
                // Note: Approach 2 Escape Hatch Styles can be injected here
            />
        </PortfolioItem>
        ))
    }
    </Projects>
);

export default FeaturedPortfolio;
