import styled from 'styled-components';
import { articleFont } from '../../../styles/_mixins';
import { fluidFontSizes } from '../../../styles/_calculations';
import { textPrimary } from '../../../styles/_theme-variables';

const linkColor = textPrimary;

export const Collection = styled.ul`
    text-align: center;
    ${fluidFontSizes.bodySize};
    padding: 0.5em 0;
`;

export const Item = styled.li`
    ${articleFont}
    ${fluidFontSizes.bodySize};
    margin-bottom: 1.5em;
    & a {
        color: ${linkColor};
        text-decoration: none;
        &:hover {
            text-decoration: underline;
        }
    }
`;