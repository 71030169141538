import React from "react";

import Asos from "./asos";
import BBC from "./bbc";
import Blinkbox from "./blinkbox";
import CapitalOne from "./capital-one";
import Microsoft from "./microsoft";
import Xbox from "./xbox";

const Logos = {
    'asos': Asos,
    'bbc': BBC,
    'blinkbox': Blinkbox,
    'capital one': CapitalOne,
    'microsoft': Microsoft,
    'xbox': Xbox
};

const Logo = ({ title, isDarkMode }) => {
    const SelectedLogo = Logos[title];
    return (<SelectedLogo isDarkMode={isDarkMode} />)
};

export default Logo;
