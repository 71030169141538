import styled from 'styled-components';
import { fluidFontSizes } from '../../../styles/_calculations';

export const Root = styled.div`
    display: flex;
    padding: 0;

    @media screen and (min-width: 50em) {
        flex-direction: row;
    }

    @media screen and (max-width: 50em) {
        flex-direction: column;
    }

    @media screen and (min-width: 64em) and (max-width: 84em) {
        flex-direction: column;
    }
`;

export const FeedWrapper = styled.div`
        flex: 1;
        ${fluidFontSizes.bodySize};

        @media screen and (min-width: 50em) {
            padding: 0 2em;
        }

        @media screen and (max-width: 50em) {
            padding: 1em;
        }

        @media screen and (min-width: 64em) and (max-width: 84em) {
            padding: 1em;
        }
`;