import React from "react";
import { ThemeContext } from 'styled-components';

import Logo from "../../logos/logo";

import {
    Companies,
    Company
} from "./featured-companies-styles"

export interface FeaturedCompaniesProps {
    featuredCompanies: any[]   
};

const FeaturedCompanies = (props: FeaturedCompaniesProps) => (
    <ThemeContext.Consumer>
        {
            theme => (
            <Companies>
            {
                props.featuredCompanies.map(c => (
                <Company key={`home-featured-companies-${c.title}`}>
                    <Logo title={c.title} isDarkMode={theme.darkMode} />
                </Company>
                ))
            }
            </Companies>
            )
        }
    </ThemeContext.Consumer>
);

export default FeaturedCompanies;
