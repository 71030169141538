import styled from 'styled-components';
import { fluidFontSizes } from '../../../styles/_calculations';

export const Companies = styled.div`
    ${fluidFontSizes.bodySize};
    padding: 0 1em 0 1em;    
    margin-left: auto;
    margin-right: auto;

    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(8rem, 0.3fr));
    grid-row-gap: 2em;
    grid-column-gap: 2em;
    grid-auto-rows: auto;
`;


export const Company = styled.div`   
    display: flex;
    flex-direction: column;
    justify-content: center;

    & svg {
        height: auto;
    }
`;