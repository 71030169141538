import styled, { css } from "styled-components";
import {
    bpMobilePhablet,
    backgroundPrimary,
    backgroundSecondary,
    textSecondary,
    leftAccentColorLighter,
    basicWhite,
    grey,
    darkGrey
} from "../_theme-variables";

import { transitionBorder, transitionColor } from '../_mixins';
import { fluidFontSizes } from '../_calculations';
import { Link as GatsbyLink } from "gatsby";
import { rightAccentColorDark, leftAccentColorDarker } from '../_theme-variables';

const sectionBackgroundColor = backgroundSecondary;
const altSectionBackgroundColor = backgroundPrimary;
const sectionHeaderColor = textSecondary;

const linkColor = props => props.theme.darkMode 
    ? basicWhite
    : rightAccentColorDark;

const projectsBackgroundColor = leftAccentColorLighter;
const projectsBackgroundColorDark = rightAccentColorDark;
const portfolioSectionHeaderColor = props => props.theme.darkMode 
    ? leftAccentColorLighter
    : rightAccentColorDark;
const feedSectionBackgroundColor = grey;
const feedSectionBackgroundColorDark = darkGrey;
const profileSectionBackgroundColor = basicWhite;
const profileSectionBackgroundColorDark = leftAccentColorDarker;

export const HeroSection = styled.div`
    max-width: 92rem;
    margin-left: auto;
    margin-right: auto;

    @media screen and (min-width: ${bpMobilePhablet}) {
        ${transitionBorder};
    }
`;

export const Section = styled.div`
    ${fluidFontSizes.bodySize};
    padding: 2em 0 2em;
`;

export const SpecialismSection = styled(Section)`
    background-color:${sectionBackgroundColor};
`;

export const CompaniesSection = styled(Section)`
    background-color:${altSectionBackgroundColor};
`;

export const CompaniesHeader = styled.div`
    ${fluidFontSizes.header3Size}
    margin-bottom: 1em;
    text-align: center;
`;

export const CompaniesSectionHeaderOverrides = css`
    color: ${sectionHeaderColor};
`;

export const ProjectsSection = styled(Section)`
    background-color: ${props => props.theme.darkMode
        ? projectsBackgroundColorDark
        : projectsBackgroundColor
    };
`;

export const PortfolioSectionHeaderOverrides = css`
    color: ${portfolioSectionHeaderColor};
`;

export const FeedSection = styled(Section)`
    background-color: ${props => props.theme.darkMode
        ? feedSectionBackgroundColorDark
        : feedSectionBackgroundColor
    };
`

export const ProfileBrowserWrapper = styled.div`
    ${fluidFontSizes.bodySize}
    padding-bottom: 1em;
    max-width: 50em;
    margin-left: auto;
    margin-right: auto;
`;

export const Link = styled(GatsbyLink)`
    ${fluidFontSizes.bodySize}
    color: ${linkColor};
    ${transitionColor};
`;

export const ProfileSection = styled(Section)`
    background-color: ${props => props.theme.darkMode
        ? profileSectionBackgroundColorDark
        : profileSectionBackgroundColor
    };
`;