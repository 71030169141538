import styled from "styled-components";

import { transitionBorder } from "../../../styles/_mixins";
import { bpMobilePhablet } from "../../../styles/_theme-variables";
import { fluidFontSizes } from '../../../styles/_calculations';

export const Projects = styled.div`
    display: flex;
    ${transitionBorder}

    @media screen and (min-width: ${bpMobilePhablet}) {
        flex-direction: row;
        flex-wrap: wrap;
    }

    @media screen and (max-width: ${bpMobilePhablet}) {
        flex-direction: column;
    }
`;

export const PortfolioItem = styled.div`
    ${fluidFontSizes.bodySize};

    @media screen and (min-width: ${bpMobilePhablet}) {
        flex-basis: 50%;
        padding: 1em 2.5em;
    }

    @media screen and (max-width: ${bpMobilePhablet}) {
        flex: 1;
        padding: 3em;
    }
`;