import styled from "styled-components";
import { articleFont, transitionColor } from "../../../styles/_mixins";
import { fluidFontSizes } from '../../../styles/_calculations';
import { textPrimary } from "../../../styles/_theme-variables";

const ItemColor = textPrimary;

export const Item = styled.li`
    ${articleFont};
    ${fluidFontSizes.bodySize};
    text-align: center;
    color: ${ItemColor};
    ${transitionColor};
`;

export const Collection = styled.ul`
    list-style: none;
    & ${Item} {
        margin-bottom: 1em;

        &:last-child {
            margin-bottom: 0;
        }
    }
`;
