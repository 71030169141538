import React from "react";
import { Root } from "./section-header-styles";
import { SectionHeaderStylesheet } from './section-header-styles/section-header-stylesheet';

export interface SectionHeaderProps {
    children: any;
    escapeHatchStylesheet?: SectionHeaderStylesheet;
}

const SectionHeader = (props: SectionHeaderProps) => (
    <Root data-testid="section-header" escapeHatchStyles={props.escapeHatchStylesheet && props.escapeHatchStylesheet.root}>
        {props.children}
    </Root>
);

export default SectionHeader;
