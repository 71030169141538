import React from "react";
import { Separator } from '../../primitives/index';
import { Header } from './block-styles';

export interface BlockProps {
    children: any;
    title: string;
    icon: any;
}

const Block = (props: BlockProps) => (
    <>
        { props.icon && <props.icon /> }
        <Separator />
        <Header>{ props.title }</Header>
        { props.children }
    </>
);

export default Block;
