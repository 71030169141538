import React from "react";
import Block from "../block";

import {
    Collection,
    Item
} from "./linksblock-styles";

export interface LinksBlockProps {
    links: any[];
}

const LinksBlock = (props: LinksBlockProps) => (
    <Collection>
        { props.links.map(s => (
            <Item>
                <a href={s.link} target="_blank">
                    { s.label }
                </a>
            </Item>
        ))}
    </Collection>
    
);

export default LinksBlock;