import styled from "styled-components";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { fluidFontSizes } from '../../../styles/_calculations';
import { textSecondary } from "../../../styles/_theme-variables";

const IconColor = textSecondary;

export const Root = styled.div`
    display: flex;
    padding: 0;
    ${fluidFontSizes.bodySize};

    @media screen and (min-width: 50em) {
        flex-direction: row;
    }

    @media screen and (max-width: 50em) {
        flex-direction: column;
    }
`;

export const SpecialistBlock = styled.div`
    flex: 1;
    ${fluidFontSizes.bodySize};

    @media screen and (min-width: 50em) {
        padding: 0 1em;
    }

    @media screen and (max-width: 50em) {
        padding: 1em;
    }
`;

export const SpecialismIcon = styled(FontAwesomeIcon)`
    ${fluidFontSizes.header1Size}
    color: ${IconColor};
    margin-left: auto;
    margin-right: auto;
    display: block;
    height: auto;
`;