import React from "react";
import { css } from 'styled-components';
import {
  Root,
  ContentWrapper,
  Content,
  Grid,
  ProfilePic,
  Left,
  Right,
  Html,
  CTA,
  Logos,
  Icon
} from "./home-hero-styles";

import { CenterWrapper } from '../../primitives/index';
import { resolveAllTechnologies } from '../../../framework/utils/technology-resolver.util';
import { withEscapeHatchStylesheet, withEscapeHatchStyles } from '../../../styles/sprinkles';

const IconUnique = () => {
  const randLeft = Math.floor(Math.random() * 90) + 1;
  const randRotation = Math.floor(Math.random() * 360) + 1;
  const randDelay = Math.floor(Math.random() * 5) + 1;
  const randDuration = Math.floor(Math.random() * 10) + 5;

  return {
    left: `${randLeft}vw`,
    transform: `rotate(${randRotation}deg)`,
    animationDelay: `${randDelay}s`,
    animationDuration: `${randDuration}s`
  };
} 

export interface HomeHeroProps {
    images: any[];
    html: string;
    learnMorePath: string;
    profilePic: any;
}


const HomeHero = (props: HomeHeroProps) => {

  const logos = resolveAllTechnologies();

  return (
    <Root
      Tag="section"
      fluid={props.images[0].src.childImageSharp.fluid}
    >
      <Logos>
        { logos.map(l => {
            const iconStyles = IconUnique();
            return (<Icon src={l.logo} style={iconStyles} alt={l.name} />);
        })}
      </Logos>
      <ContentWrapper>
        <Content>
          <CenterWrapper>
            <Grid>
              <Left>
                <Html html={props.html} />
                <div>
                  <CTA to={props.learnMorePath}>Learn more</CTA>
                </div>
              </Left>
              <Right>
                <ProfilePic fluid={props.profilePic} />
              </Right>
            </Grid>
          </CenterWrapper>
        </Content>
      </ContentWrapper>
    </Root>
  )
};

export default HomeHero;
