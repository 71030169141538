import React from "react";
import { SpecialistBlock, SpecialismIcon, Root } from './specialisms-styles';
import ListBlock from '../../_molecules/listblock';
import { resolveIcon } from '../../../framework/icon-resolver';

export interface SpecialismsProps {
    technicalItems: string[];
    catalyticItems: string[];
    downtimeItems: string[];
}

const Specialisms = (props: SpecialismsProps) => (
    <Root>
        <SpecialistBlock>
            <ListBlock
                title="What I specialise in as an engineer..."
                items={props.technicalItems}
                icon={() => (
                    <SpecialismIcon
                        icon={resolveIcon('laptop')}
                        size="3x"
                    />
                )}
            >            
            </ListBlock>
        </SpecialistBlock>
        <SpecialistBlock>
            <ListBlock
                title="...what I also do as an engineer..."
                items={props.catalyticItems}
                icon={() => (
                    <SpecialismIcon
                        icon={resolveIcon('teacher')}
                        size="3x"
                    />
                )}
            >            
            </ListBlock>
        </SpecialistBlock>
        <SpecialistBlock>
            <ListBlock
                title="...when I'm not coding I'm..."
                items={props.downtimeItems}
                icon={() => (
                    <SpecialismIcon
                        icon={resolveIcon('glasses')}
                        size="3x"
                    />
                )}
            >            
            </ListBlock>
        </SpecialistBlock>
    </Root>
);

export default Specialisms;
