import { TechnologyViewModel } from '../interfaces/technology-view-model.interface';

import * as gitLogo from '../../content/logos/git-scm-ar21.svg';
import * as cSharpLogo from '../../content/logos/C-Sharp-logo-vector-01.svg';
import * as htmlLogo from '../../content/logos/HTML-5-logo-vector-01.svg';
import * as jsLogo from '../../content/logos/JavaScript-logo-vector-01.svg';
import * as cssLogo from '../../content/logos/CSS-3-logo-vector-01.svg';
import * as dotnetLogo from '../../content/logos/net-seeklogo.com.svg';
import * as angularJsLogo from '../../content/logos/Angular-JS-logo-vector-01.svg';
import * as nodeJsLogo from '../../content/logos/Node-JS-logo-vector-01.svg';
import * as jqueryLogo from '../../content/logos/JQuery-Logo.svg';
import * as umbracoLogo from '../../content/logos/umbraco_logo_blue.svg';
import * as mssqlLogo from '../../content/logos/microsoft-sql-server.svg';
import * as reactLogo from '../../content/logos/React-icon.svg';
import * as gatsbyLogo from '../../content/logos/Gatsby-Monogram.svg';
import * as sassLogo from '../../content/logos/Sass_Logo_Color.svg';
import * as azureLogo from '../../content/logos/Microsoft_Azure_Logo.svg';
import * as graphqlLogo from '../../content/logos/GraphQL_Logo.svg';
import * as flashLogo from '../../content/logos/Adobe_Flash_Player_SVG.svg';


const logos = {
    'git': gitLogo,
    'c#': cSharpLogo,
    '.net (c#)': cSharpLogo,
    'html': htmlLogo,
    'javascript': jsLogo,
    'css': cssLogo,
    'asp.net': dotnetLogo,
    'angularjs': angularJsLogo,
    'nodejs': nodeJsLogo,
    'jQuery': jqueryLogo,
    'umbraco': umbracoLogo,
    'mssql': mssqlLogo,
    'react': reactLogo,
    'gatsbyjs': gatsbyLogo,
    'sass': sassLogo,
    'azure': azureLogo,
    'graphql': graphqlLogo,
    'flash': flashLogo
}

export const resolveTechnologies: (techStrings: string[]) => TechnologyViewModel[] =
    (techStrings: string[]) => {
        return techStrings.map(t => ({
            name: t,
            logo: logos[t] || null
        }))
    };

export const resolveAllTechnologies: () => TechnologyViewModel[] = () => {
        return Object.entries(logos).map(([key, value]) => ({
            name: key,
            logo: value
        }))
    };