import styled from 'styled-components';
import { fluidFontSizes } from '../../../styles/_calculations';
import { headerFontMixin } from '../../../styles/_mixins';
import { Link } from 'gatsby';
import { textPrimary, leftAccentColorLighter, primaryColorDarker } from '../../../styles/_theme-variables';

const linkColor = textPrimary;
const linkColorHover = leftAccentColorLighter;
const headerLinkColor = props => props.theme.darkMode 
    ? leftAccentColorLighter
    : primaryColorDarker;

export const Root = styled.div`
    ${fluidFontSizes.smallPrintSize};
`;

export const Header = styled.div`
    ${fluidFontSizes.smallPrintSize};
    margin-bottom: 1em;
    ${headerFontMixin}
    text-align: center;
`;

export const HeaderLink = styled(Link)`
    margin-bottom: 0.5em;
    display: inline-block;
    color: ${headerLinkColor};
    max-width: 12em;
    line-height: 1.2;
    text-decoration: none;
    &:hover {
        text-decoration: underline;
    }
`;

export const Items = styled.ul``;

export const Item = styled.li`
    text-align: center;
    ${fluidFontSizes.smallPrintSize};
    margin: 1.5em 0;
`;

export const ItemLink = styled.a`
    color: ${linkColor};
    display: inline-block;
    text-decoration: none;

    &:hover {
        text-decoration: underline;
        color: ${linkColorHover};
    }
`;
