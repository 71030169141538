import styled from "styled-components";
import { textPrimary } from '../../../styles/_theme-variables';
import { transitionColor, articleFont } from '../../../styles/_mixins';
import { fluidFontSizes } from '../../../styles/_calculations';

const ItemTextColor  = textPrimary;

export const Root = styled.div`
    ${fluidFontSizes.bodySize}
    padding: 1em;
`;

export const PostWrapper = styled.div`
    ${fluidFontSizes.header3Size}
    margin-bottom: 2em;
    margin-left: auto;
    margin-right: auto;
    max-width: 46rem;

    &:last-child {
        margin-bottom: 0;
    }
`;

export const PostDescription = styled.p`
    color: ${ItemTextColor};
    ${transitionColor};
    ${articleFont};
    ${fluidFontSizes.bodySize}
`;