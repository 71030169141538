import React from "react";
import ListingItem from "../../_molecules/listing-item";
import {
    Root,
    PostWrapper,
    PostDescription
} from "./featured-posts-styles";

export interface Post {
    node: any;
};

export interface FeaturedPostsProps {
    posts: Post[]
};

const FeaturedPosts = (props: FeaturedPostsProps) => (
    <Root>
        {
            props.posts.map(({ node: a }) =>
                (<PostWrapper key={`home-latest-posts-${a.title}`}>
                    <ListingItem
                        title={a.title}
                        image={a.heroImages[0]}
                        publishDate={a.publishDate}
                        slug={a.slug}
                    >
                        <PostDescription>
                            { a.metaDescription}
                        </PostDescription>
                    </ListingItem>
                </PostWrapper>)
            )
        }
    </Root>
);

export default FeaturedPosts;