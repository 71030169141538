import React from "react";

import { Collection, Item } from "./listblock-styles";
import Block from "../block";

export interface ListBlockProps {    
    title: string;
    items: string[];
    icon: any;
    children: any;
}

const ListBlock = (props: ListBlockProps) => (
    <Block
        title={props.title}
        icon={props.icon}
    >
        <Collection>
            { props.items.map(s => (
                <Item>{ s }</Item>
            ))}
        </Collection>
    </Block>
);

export default ListBlock;
