import styled from 'styled-components';
import { fluidFontSizes } from '../../../styles/_calculations';
import { headerFontMixin, articleFont } from '../../../styles/_mixins';
    import { leftAccentColorLighter, primaryColorDarker, textPrimary } from '../../../styles/_theme-variables';
import { transparentize } from 'polished';

const headerLinkColor = props => props.theme.darkMode 
    ? leftAccentColorLighter
    : primaryColorDarker;

const tweetColor = textPrimary;
const linkColor = props => props.theme.darkMode 
    ? leftAccentColorLighter
    : primaryColorDarker;
const itemSeparatorColor = props => transparentize(0.7, props.theme.styles.color.leftAccentColorLighter);

export const Root = styled.div`
    ${fluidFontSizes.smallPrintSize};
`;

export const Header = styled.div`
    ${fluidFontSizes.smallPrintSize};
    margin-bottom: 1em;
    ${headerFontMixin}
    text-align: center;
`;

export const HeaderLink = styled.a`
    margin-bottom: 0.5em;
    display: inline-block;
    color: ${headerLinkColor};
    text-decoration: none;
    &:hover {
        text-decoration: underline;
    }
`;

export const Tweet = styled.div`
    margin-bottom: 1em;
    border-bottom: 1px solid ${itemSeparatorColor};
    padding-bottom: 1em;
    ${articleFont}
    max-width: 18rem;
    margin-left: auto;
    margin-right: auto;
`;

export const TweetContent = styled.p`
    color: ${tweetColor};
`;

export const TweetHeader = styled.div`
    padding: 0.5em 0;
`;

export const TweetLink = styled.a`
    ${fluidFontSizes.smallPrintSize};
    color: ${linkColor};
    display: inline-block;
`;

export const Date = styled.span`
    display: inline-block;
    width: 100%;
    color: ${tweetColor};
`;