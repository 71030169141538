import React from "react";
import Block from "../block";
import { resolveIcon } from "../../../framework/icon-resolver";
import { SpecialismIcon } from '../../_organisms/specialisms/specialisms-styles';
import { Root, Header, HeaderLink, ItemLink, Item, Items } from './content-feed-styles';

export interface ContentItemProps {
    title: string;
    link: string;
}

export interface ContentFeedProps {
    title: string;
    icon: string;
    items: ContentItemProps[];
    CTA: string;
    CTAlink: string;
};

const ContentFeed = (props: ContentFeedProps) => {
    return (
        <Block
            title={props.title}
            icon={() => (
                <SpecialismIcon
                    icon={resolveIcon(props.icon)}
                    size="3x"
                />
            )}
        >
            <Root>                
                <Items>
                    {props.items.map((item: ContentItemProps) => (
                        <Item>
                            <ItemLink href={item.link} target="_blank">{ item.title}</ItemLink>
                        </Item>
                    ))}
                </Items>
                <Header>
                    <div>
                        <HeaderLink to={props.CTAlink}>{props.CTA}</HeaderLink>
                    </div>
                </Header>
            </Root>
        </Block>
    )
}

export default ContentFeed;
