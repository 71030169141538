import styled from "styled-components";
import { headerFontMixin } from '../../../styles/_mixins';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { fluidFontSizes } from "../../../styles/_calculations";
import { bpPhabletTablet, backgroundPrimary, darkGrey, leftAccentColorLighter, primaryColorDarker, backgroundSecondary } from "../../../styles/_theme-variables";

const groupBackgroundColor =  props => props.theme.darkMode
    ?   darkGrey
    :   backgroundPrimary;

const browserButtonColor = props => props.theme.darkMode 
    ? leftAccentColorLighter
    : primaryColorDarker;

const iconColor = backgroundSecondary;

export const Root = styled.div`
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    ${fluidFontSizes.bodySize};
    padding: 0 1em;
    
    @media screen and (min-width: 45em) {
        grid-column-gap: 2em;
        grid-row-gap: 2em;
        grid-template-columns: repeat(3, 1fr);
    }
`;

export const GroupRoot = styled.div`
    padding: 1em;

    @media screen and (max-width: 45em) {
        &:last-child {
            border: none;
        }
    }

    @media screen and (min-width: 45em) {
        grid-template-columns: repeat(2, 1fr);
        background-color: ${groupBackgroundColor};
    }
`;

export const BrowserButton = styled.button`
    display: block;
    margin-left: auto;
    margin-right: auto;

    cursor: pointer;

    background-color: transparent;
    border: none;
    ${headerFontMixin};
    ${fluidFontSizes.smallPrintSize}
    color: ${browserButtonColor};

    &:hover {
        text-decoration: underline;
    }

    &.active {
        font-weight: bold;
    }
`;

export const TileIcon = styled(FontAwesomeIcon)`
    width: 1.6rem;
    font-size: 1.6rem;
    color: ${iconColor};
`;

export const TileLabel = styled.span`
    display: block;
`;

export const TileLink = styled.a`
    float: right;
    cursor: pointer;
    &:hover {
        text-decoration: underline;
    }
`;

export const PagingControls = styled.div`
    display: flex;
    ${fluidFontSizes.bodySize}
    height: 2em;
`;

export const PagingControl = styled.div`
    flex: 1;
`;

export const Message = styled.p`
    ${headerFontMixin}
    ${fluidFontSizes.bodySize}
    text-align: center;
    margin: 3em 0;
`;

export const TabContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;

    @media screen and (min-width: ${bpPhabletTablet}) {
        min-height: 18rem;
    }

    @media screen and (max-width: ${bpPhabletTablet}) {
        min-height: 10rem;
    }
`;